"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    api: {
        foodOpsApiBaseUrl: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/foodops/graphql',
        commonApi: 'https://atg9nr0hg8.execute-api.us-east-1.amazonaws.com/dev/common',
        apiKey: 'xablauapikeyxablau123-dev',
        webSocketBaseUrl: 'wss://002hggu6vg.execute-api.us-east-1.amazonaws.com/dev'
    },
    cloudfront: 'd3blgs78fm7jw.cloudfront.net',
    cognito: {
        foodOpsUserPoolId: 'us-east-1_ZKSUKDvZJ',
        foodOpsUserPoolClientId: '6nfslo83b783sse7u9ls67jjvm'
    },
    pinpoint: '42d49ab8c5ac41a1a7dfed51114ca84d',
    region: 'us-east-1',
    secretsManager: {
        mapsApiKey: 'AIzaSyDLP8REBjQ8Goq1vIyX522we4aMnByRU7k'
    }
};
exports.default = config;
